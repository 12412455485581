<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 text-start my-5">
        <span class="underlined underline-clip"> L'Aquila Post Sisma </span>
        <p class="subtitle"> Cambia la geografia della città, mancano i luoghi di aggregazione </p>
      </div>
      <div class="col-lg-12 text-end my-5">
        <span class="underlined underline-clip"> Necessità </span>
        <p class="subtitle"> Gruppo di professionisti con le stesse esigenze che si mettono insieme </p>
      </div>
      <div class="col-lg-12 text-start my-5">
        <span class="underlined underline-clip"> Come? </span>
        <p class="subtitle"> Unendosi in forma associativa e formando una community </p>
      </div>
    </div>
    <div class="row margin-section">
      <p class="p-title text-center"> Leggi l'estratto della nostra intervista per conoscerci meglio. </p>
      <div class="col-lg-12 my-auto">
        <img alt="Magazine" width="70%" src="/assets/images/about/magazine.png">
      </div>
      <div class="col-lg-12">
        <img alt="Magazine" width="70%" src="/assets/images/about/magazine2.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

.subtitle {
  font-size: 25px;
}

.underline-clip:after {
  content: '';
  position: absolute;
  top: 60%;
  width: 150%;
  aspect-ratio: 3 / 1;
  left: 55%;
  transform: translate(-50%, 0);
  border-radius: 50%;
  z-index: -1;
  border: 8px solid hsla(102, 40%, 53%, 0.49);
  /* Use a clip-path to hide and show the bits you want */
  clip-path: polygon(0 0, 15% 20%, 100% 0);
}
.underlined {
  position: relative;
  font-family: onest-bold;
  font-size: 70px;
  line-height: 40px;
}
</style>