<template>
  <div class="container-fluid footer_section layout_padding">
    <div class="row">
      <div class="col-lg-4 my-5">
        <img class="footer_logo" alt="Logo" src="/assets/images/logos/logo.png">
      </div>
      <div class="col-lg-4 my-5">
        <h2 class="useful_text">Contatti</h2>
        <p class="footer_links">info@strangeoffice.com</p>
        <h2 class="useful_text">Phone</h2>
        <p class="footer_links">3661470797</p>
        <p class="footer_links">3355286578</p>
      </div>
      <div class="col-lg-4 my-auto">
        <h1 class="follow_text">Seguici su</h1>
        <div class="social_icon">
          <ul>
            <li><a href="https://www.facebook.com/StrangeOffice"><img alt="" src="/assets/images/footer/fb-icon.png"></a></li>
            <li><a href="https://twitter.com/Strange_Office"><img alt="" src="/assets/images/footer/twitter-icon.png"></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>