<template>
  <div class="container">
    <div class="row margin-section">
      <div class="col-lg-12 my-5">
        <h1 class="title"> Coworking </h1>
        <h2 class="gradient">Reinventiamo il luogo di lavoro. <br> Reinventiamo il lavoro.</h2>
      </div>
    </div>
    <div class="row margin-section">
      <div class="col-lg-6">
        <img width="80%" alt="Place" src="/assets/images/home/office.jpg">
      </div>
      <div class="col-lg-6 my-auto">
        <p class="p-title"> Strange Office è uno spazio di co-working, ma soprattutto un luogo di opportunità. </p>
        <p class="text">
          Opportunità di passare ad un livello più avanzato del proprio lavoro, <br> opportunità di conoscere e
          interagire con tante personalità affini e differenti, <br> opportunità di ampliare il proprio bacino di
          clienti e partners.
        </p>
      </div>
    </div>
    <div class="row my-5">
      <div class="col-lg-12">
        <p class="p-title text-center">
          Dall’esperienza pregressa di alcuni soci, nasce l’idea di un ufficio condiviso.
          <br>
          <span class="detail"> Sì, ma condividere cosa? </span>
        </p>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row banner-curve-up" style="background-color: #73b656">
      <div style="height: 100px"> </div>
    </div>
    <div class="row pt-5" style="background-color: #73b656">
      <div class="col-lg-4">
        <div class="card border-0" style="background-color: #73b656">
          <svg xmlns="http://www.w3.org/2000/svg" height="2em" fill="#fff" viewBox="0 0 576 512">
            <path
                d="M408 120c0 54.6-73.1 151.9-105.2 192c-7.7 9.6-22 9.6-29.6 0C241.1 271.9 168 174.6 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120zm8 80.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4zM137.6 138.3c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3l122.6-49zM327.8 332c13.9-17.4 35.7-45.7 56.2-77V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0zM288 152a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
          </svg>
          <div class="card-body">
            <h5 class="card-title text-white logos-descr" style="background-color: #73b656">Spazi</h5>
            <p class="text text-center text-white lh-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card border-0" style="background-color: #73b656">
          <svg xmlns="http://www.w3.org/2000/svg" height="2em" fill="#fff" viewBox="0 0 320 512">
            <path
                d="M48.1 240c-.1 2.7-.1 5.3-.1 8v16c0 2.7 0 5.3 .1 8H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H60.3C89.9 419.9 170 480 264 480h24c17.7 0 32-14.3 32-32s-14.3-32-32-32H264c-57.9 0-108.2-32.4-133.9-80H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H112.2c-.1-2.6-.2-5.3-.2-8V248c0-2.7 .1-5.4 .2-8H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H130.1c25.7-47.6 76-80 133.9-80h24c17.7 0 32-14.3 32-32s-14.3-32-32-32H264C170 32 89.9 92.1 60.3 176H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H48.1z"/>
          </svg>
          <div class="card-body">
            <h5 class="card-title text-white logos-descr">Spese</h5>
            <p class="text text-center text-white lh-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card border-0" style="background-color: #73b656">
          <svg xmlns="http://www.w3.org/2000/svg" height="2em" fill="#fff" viewBox="0 0 384 512">
            <path d="M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z"/>
          </svg>
          <div class="card-body">
            <h5 class="card-title text-white logos-descr">Idee</h5>
            <p class="text text-center text-white lh-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card my-4 border-0" style="background-color: #73b656">
          <svg xmlns="http://www.w3.org/2000/svg" height="2em" fill="#fff" viewBox="0 0 576 512">
            <path
                d="M400 0H176c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H357.9C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z"/>
          </svg>
          <div class="card-body">
            <h5 class="card-title text-white logos-descr">Competenze</h5>
            <p class="text text-center text-white lh-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card my-4 border-0" style="background-color: #73b656">
          <svg xmlns="http://www.w3.org/2000/svg" height="2em" fill="#fff" viewBox="0 0 512 512">
            <path
                d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"/>
          </svg>
          <div class="card-body">
            <h5 class="card-title text-white logos-descr">Clienti</h5>
            <p class="text text-center text-white lh-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card my-4 border-0" style="background-color: #73b656">
          <svg xmlns="http://www.w3.org/2000/svg" height="2em" fill="#fff" viewBox="0 0 384 512">
            <path
                d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM96 75V64H288V75c0 19-5.6 37.4-16 53H112c-10.3-15.6-16-34-16-53zm16 309c3.5-5.3 7.6-10.3 12.1-14.9L192 301.3l67.9 67.9c4.6 4.6 8.6 9.6 12.1 14.9H112z"/>
          </svg>
          <div class="card-body">
            <h5 class="card-title text-white logos-descr">Tempo</h5>
            <p class="text text-center text-white lh-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row banner-curve-down" style="background-color: #73b656">
      <div style="height: 100px"> </div>
    </div>
  </div>
  <div class="container">
    <div class="row margin-section">
      <div class="col-lg-12">
        <p class="p-title text-center lh-1"> L'ufficio </p>
        <p class="text text-center">
          20 postazioni, 1 sala riunione e 1 sala comune <br>
          armadietti, angolo ristoro, angolo copisteria
        </p>
        <div class="row">
          <div class="col-lg-6 my-3">
            <img alt="Sala Coworking" class="office" src="/assets/images/home/office.jpg">
            <h6 class="mt-4 detail"> Sala coworking </h6>
            <p class="text text-center lh-base">
              Attrezzata con scrivanie, sedie ergonomiche, armadietti con chiave, connessione wi-fi, e stampante/scanner
            </p>
          </div>
          <div class="col-lg-6 my-3">
            <img alt="Sala Riunioni" class="meeting" src="/assets/images/riunioni.png">
            <h6 class="mt-4 detail"> Sala riunioni </h6>
            <p class="text text-center lh-base">
              Attrezzata con unico tavolo centrale e 10 sedie, lavagna magnetica, videoproiettore, apple TV e telo
              cinema.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// import required modules
import {Autoplay} from 'swiper/modules';

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
}
</script>

<style scoped>

.gradient {
  font-size: 25px;
  background: -webkit-linear-gradient(45deg, rgba(44, 62, 80, 1) 13%, rgba(115, 182, 86, 1) 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

img {
  box-shadow: 5px 10px 18px #dadada;
  border-radius: 20px;
}

.logos-descr {
  font-family: onest-reg;
  font-size: 25px;
}

.banner-curve-up {
  clip-path: ellipse(50% 80% at center 100%);
}
.banner-curve-down {
  clip-path: ellipse(50% 80% at center -5%);
}
/* MOBILE */
@media (max-width: 576px) {


}
.office, .meeting {
  max-height: 300px;
  width: auto;
}
</style>