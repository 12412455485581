<template>
  <nav class="navbar navbar-expand-lg">
    <router-link to="/" class="navbar-brand">
      <img class="logo" alt="Logo" src="/assets/images/logos/logo.png">
    </router-link>
    <button v-on:click="toggleSidebar()" class="navbar-toggler" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse" v-bind:class="{'collapse' : !sidebar}" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto"> <!-- Aggiunta della classe "ml-auto" -->
        <li class="nav-item">
          <router-link v-bind:class="{'active' : this.$route.name === 'Home'}" class="nav-item" to="/">
            Home
          </router-link>
        </li>
        <li class="nav-item">
          <router-link v-bind:class="{'active' : this.$route.name === 'About'}" class="nav-item" to="/about">
            Chi siamo
          </router-link>
        </li>
        <li class="nav-item">
          <router-link v-bind:class="{'active' : this.$route.name === 'Services'}" class="nav-item" to="/services">
            I nostri servizi
          </router-link>
        </li>
        <li class="nav-item">
          <router-link v-bind:class="{'active' : this.$route.name === 'Contact'}" class="nav-item" to="/contact">
            Contatti
          </router-link>
        </li>
        <li class="nav-item mt-2">
          <router-link v-bind:class="{'active-membership' : this.$route.name === 'Membership'}" class="nav-item membership" to="/membership">
            Membership
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
  computed: {
    sidebar() {
      return this.$store.getters.getSidebar;
    },
  },
  methods: {
    toggleSidebar() {
      this.$store.commit("setSidebar", !this.sidebar);
    },
  }
}
</script>

<style scoped>
.navbar-nav.ml-auto {
  margin-left: auto; /* Utilizzo di "margin-left: auto" per allineare gli elementi a destra */
}

.active {
  border-bottom: 2px solid #73b656;
  color: #73b656 !important;
}

.logo {
  width: 100px;
  margin-left: 10px;
}

.active-membership {
  background-color: #73b656 !important;
  color: whitesmoke !important;
  border-color: whitesmoke !important;
}

.membership {
  border: 2px solid #262626;
  padding: 10px;
  border-radius: 5px;
}
.navbar{
  margin-right: 25px;
  margin-left: 25px;
}
.nav-item {
  font-family: onest-bold;
  font-size: 18px;
  color: #262626;
  margin-right: 25px;
  margin-bottom: 10px;
}

.menu-mobile {
  padding-left: 10px;
  padding-right: 10px;
}

.menu-mobile svg {
  margin-right: 10px;
}

.navbar-toggler{
  border: none;
}
.navbar-toggler:focus , .navbar-toggler:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}



</style>