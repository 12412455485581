import { createStore } from 'vuex'

export default createStore({
  state: {
    sidebar: false,
  },
  getters: {
    getSidebar(state) {
      return state.sidebar;
    },
  },
  mutations: {
    setSidebar(state, value) {
      state.sidebar = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
