<template>
  <div class="container">
    <div class="row my-5">
      <div class="col-lg-6">
        <h2 class="p-title"> I nostri servizi </h2>
        <p class="text"> <span class="detail">></span> Scrivania, sedia e armadietto condiviso </p>
        <p class="text"> <span class="detail">></span> Copertura internet WiFi </p>
        <p class="text"> <span class="detail">></span> Energia elettrica </p>
        <p class="text"> <span class="detail">></span> Riscaldamento e aria condizionata </p>
        <p class="text"> <span class="detail">></span> Area relax con divani </p>
        <p class="text"> <span class="detail">></span> Sala riunioni </p>
        <p class="text"> <span class="detail">></span> Pulizia e manutenzione dello spazio </p>
        <p class="text"> <span class="detail">></span> Reception accoglienza visitatori e casella postale </p>
      </div>
      <div class="col-lg-6 my-auto">
        <img alt="" style="position: relative; top: 50%; right: 3%; width: 65%; border-radius: 10px;" src="/assets/images/logoVetro.JPG">
      </div>
    </div>
    <div class="row margin-section">
      <div class="col-lg-12">
        <h2 class="p-title lh-1 text-center detail">Le nostre iniziative </h2>
        <p> In partnership con ass. “L’Aquila Che Rinasce” nasce un ciclo di eventi chiamati Onda d’Innovazione. </p>
        <p> Attività ed eventi organizzati dal 2015: </p>
      </div>
      <div class="row mt-3">
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title detail">StartUp MeetUP</h5>
              <p class="mx-3 my-4">Aperitivi a tema innovazione con dibattiti e drink</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title detail">StartUp Bus</h5>
              <p class="mx-3 my-4">Ospiti di una delle 2 tappe italiane del tour europeo</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title detail">L’Aquila Fantasy Fest</h5>
              <p class="mx-3 my-4">Primo evento a tema fantasy/games dell’Aquila</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title detail">Strange Office Academy</h5>
              <p class="mx-3 my-4">Eventi di formazione professionale a L'Aquila</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title detail">Sulle Tracce del Drago</h5>
              <p class="mx-3 my-4">Rassegna culturale nel programma della perdonanza Celestinianao</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title detail">Buongiorno Italia</h5>
              <p class="mx-3 my-4">Ospiti della trasmissione in diretta su Rai3</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row margin-section">
      <div class="col-lg-6 my-auto">
        <h2 class="p-title text-center"> I nostri corsi </h2>
      </div>
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-6">
            <svg xmlns="http://www.w3.org/2000/svg" height="2em" fill="#73b656" viewBox="0 0 512 512"><path d="M168.5 72L256 165l87.5-93h-175zM383.9 99.1L311.5 176h129L383.9 99.1zm50 124.9H256 78.1L256 420.3 433.9 224zM71.5 176h129L128.1 99.1 71.5 176zm434.3 40.1l-232 256c-4.5 5-11 7.9-17.8 7.9s-13.2-2.9-17.8-7.9l-232-256c-7.7-8.5-8.3-21.2-1.5-30.4l112-152c4.5-6.1 11.7-9.8 19.3-9.8H376c7.6 0 14.8 3.6 19.3 9.8l112 152c6.8 9.2 6.1 21.9-1.5 30.4z"/></svg>
            <p class="my-3"> Personal Branding </p>
          </div>
          <div class="col-lg-6">
            <svg xmlns="http://www.w3.org/2000/svg" height="2em" fill="#73b656" viewBox="0 0 576 512"><path d="M64 112c-8.8 0-16 7.2-16 16V384c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H64zM0 128C0 92.7 28.7 64 64 64H512c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM176 320H400c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V336c0-8.8 7.2-16 16-16zm-72-72c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H200c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H200c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H280c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H280c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H360c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H360c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H440c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H440c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16z"/></svg>
            <p class="my-3"> Fare blogging nel 2016 </p>
          </div>
          <div class="col-lg-6 mt-3">
            <svg xmlns="http://www.w3.org/2000/svg" height="2em" fill="#73b656" viewBox="0 0 640 512"><path d="M533.6 32.5C598.5 85.3 640 165.8 640 256s-41.5 170.8-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"/></svg>
            <p class="my-3"> Inglese ai tempi dei Social Media </p>
          </div>
          <div class="col-lg-6 mt-3">
            <svg xmlns="http://www.w3.org/2000/svg" height="2em" fill="#73b656" viewBox="0 0 512 512"><path d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192 160 64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V352l8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V300.4c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4V32zm-64 76.7V240 371.3C357.2 317.8 280.5 288 200.7 288H192V192h8.7c79.8 0 156.5-29.8 215.3-83.3z"/></svg>
            <p class="my-3"> Public Speaking </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services"
}
</script>

<style scoped>
.p-title {
  font-size: 70px;
}
.text {
  border-top: 0.5px solid #d5d5d5;
  margin-bottom: 0;
  padding: 10px;
}
.card-title {
  font-size: 30px;
  margin: 15px 0;
  font-family: onest-bold;
}
.detail {
  font-family: onest-bold;
}
.card {
  border:none;
  margin-bottom: 10px;
}
.card-body {
  background: linear-gradient(white, white) padding-box,
  linear-gradient(20deg, rgba(44,62,80,1) 13%, rgba(115,182,86,1) 90%) border-box;
  border-radius: 20px;
  border: 4px solid transparent;
}
</style>