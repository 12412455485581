<template>
<div class="container">
  <div class="row margin-section">
    <div class="col-lg-12 col-12">
      <h1 class="title mb-5"> Membership </h1>
    </div>
    <div class="col-lg-4 col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">MINI</h5>
          <h6 class="card-subtitle detail">€ 125,00 al mese</h6>
          <p class="card-text"> 3 giorni a settimana <br> Postazione condivisa </p>
          <div class="button">
            <a href="mailto:info@strangeoffice.com?
            &subject=MINI Membership" class="primary-btn">Acquista</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">CITIZEN</h5>
          <h6 class="card-subtitle detail">€ 190,00 al mese <span style="font-weight: 700"> * </span></h6>
          <p class="card-text"> Tutti i giorni <br> Postazione dedicata </p>
          <div class="button">
            <a href="mailto:info@strangeoffice.com?
            &subject=CITIZEN Membership" class="primary-btn">Acquista</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">NANO</h5>
          <h6 class="card-subtitle detail">€ 350,00 all'anno</h6>
          <p class="card-text">1 giorno a settimana <br> Postazione condivisa</p>
          <div class="button">
            <a href="mailto:info@strangeoffice.com?
            &subject=NANO Membership" class="primary-btn">Acquista</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-12">
      <p class="notes"> <span style="font-weight: 700; color: #73b656; font-size: 25px">* </span> Il prezzo è di € 190,00 al mese per persona in caso di canone anticipato di 3 mesi e di € 250,00 al mese in caso di canone mensile </p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Membership"
}
</script>

<style scoped>

.card {
  border:none;
  margin-bottom: 10px;
}
.card-body {
  background: linear-gradient(white, white) padding-box,
  linear-gradient(30deg, rgba(44,62,80,1) 40%, rgba(115,182,86,1) 60%) border-box;
  border-radius: 20px;
  border: 4px solid transparent;
}

.card-title {
  font-size: 55px;
  padding: 50px 30px;
  font-family: onest-bold;
  margin: 0;
}

.card-subtitle {
  font-size: 25px !important;
  padding: 15px 0px;
  margin: 0;
}

.card-text {
  margin: 0;
  font-family: onest-reg !important;
}

.button {
  padding: 60px 0;
}

.button-text {
  text-transform: uppercase;
  color: #fff;
  background-color: #2c3e50;
  padding: 20px 30px;
  font-family: onest-bold;
  font-size: 18px;
  border-radius: 10px;
  letter-spacing: 1px;
}

.button-text:hover {
  background-color: #73b656;
}

.notes {
  margin: 20px 0 50px 0;
  font-size: 15px;
  color: rgba(44, 62, 80, 0.59);
}
</style>