<template>
  <Header/>
  <router-view :key="$route.path"/>
  <Footer/>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  components: {Footer, Header}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@font-face {
  font-family: onest-reg;
  src: url('../public/assets/fonts/onest/Onest-Regular.ttf');
}

@font-face {
  font-family: onest-light;
  src: url('../public/assets/fonts/onest/Onest-Light.ttf');
}

@font-face {
  font-family: onest-bold;
  src: url('../public/assets/fonts/onest/Onest-SemiBold.ttf');
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.container{
  padding-top: 30px;
}
</style>
