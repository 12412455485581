<template>
  <div class="container">
    <div class="row margin-section">
      <div class="col-lg-12">
        <h1 class="title"> Get in touch! </h1>
        <h3 class="gradient mt-3"> Scambiamoci idee, criticità e soluzioni! <br> Contattaci sui nostri canali social e su
          info@strangeoffice.com e restiamo in contatto.</h3>
      </div>
    </div>
    <div class="row margin-section">
      <div class="col-lg-12">
        <h2 style="font-weight: 700"> La nostra sede </h2>
        <h4 class="text-icon p-0 m-0">
          <svg class="mx-3" xmlns="http://www.w3.org/2000/svg" height="1em" fill="#73b656" viewBox="0 0 384 512">
          <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
        </svg>Via Vicentini - L'Aquila, Galleria Comm.le Via Roma</h4>
        <br>
        <iframe class="my-4"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47174.973410870116!2d13.311296393885156!3d42.35455005470762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132fd3d663025819%3A0xe163bd373654687a!2sGalleria%20Commerciale%20via%20Roma!5e0!3m2!1sit!2sit!4v1698233993715!5m2!1sit!2sit"
            width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>
.text-icon {
  display: inline-block;
}

.gradient {
  background: -webkit-linear-gradient(45deg, rgba(44, 62, 80, 1) 13%, rgba(115, 182, 86, 1) 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>